import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle, useContext } from 'react';
import { fabric } from 'fabric';
import { forEach, size } from 'lodash';

import styles from "./TrainingBoard.module.scss";

import { BoardContext } from './BoardContext';

import { colorCoding } from './utils/colorCoding';
import { imgCursor, svgRotateIcon } from './utils/svgIcons';
import { dotInitialProps, initialFigureProps, initialGroupProps, initialShapeProps, initialTextInputProps, resizeShapeProps } from './utils/initialProps';

import ball from './../../../assets/training-board/ball.svg';
import x_cone from './../../../assets/training-board/x-cone.svg';
import cone from './../../../assets/training-board/cone.svg';
import goal from './../../../assets/training-board/goal.svg';
import x_goal from './../../../assets/training-board/x-goal.svg';
import mannequin from './../../../assets/training-board/mannequin.svg';
import ladder from './../../../assets/training-board/ladder.svg';
import hurdle from './../../../assets/training-board/hurdle.svg';
import slalom_pole from './../../../assets/training-board/slalom-pole.svg';

import player from './../../../assets/training-board/player.svg';
import goalkeeper from './../../../assets/training-board/goalkeeper.svg';
import triangle_player from './../../../assets/training-board/triangle-player.svg';
import circle_player from './../../../assets/training-board/circle-player.svg';

fabric.Object.prototype.toObject = (function (toObject) {
    return function () {
        // Call the original toObject method and add custom properties
        return fabric.util.object.extend(toObject.call(this), {
            customProps: this.customProps, // Add custom props here
            ...(this.radius && { radius: this.radius }), // for circle
            ...(this.rx && this.ry && { rx: this.rx, ry: this.ry, }), // for oval
            ...(this.customProps?.type == "text" && { ...initialTextInputProps }),
            ...(this.fontSize && { fontSize: this.fontSize }),
            ...(this.fontWeight && { fontWeight: this.fontWeight }),
            ...(this.fontFamily && { fontFamily: this.fontFamily }),
            ...(this.shadow && { shadow: this.shadow }),
            ...(this.text && { text: this.text }),
            ...(this.type == "text" && { text: this.text ?? "" }),
        });
    };
})(fabric.Object.prototype.toObject);

fabric.Object.prototype.setCustomControls = function () {
    // Custom render icon function for rotation control
    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        const size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(imgIcon, -size / 2, -size / 2, size, size);
        ctx.restore();
    }

    const rotateIcon = `data:image/svg+xml;utf8,${encodeURIComponent(svgRotateIcon)}`;
    const imgIcon = document.createElement('img');
    imgIcon.src = rotateIcon;

    // Create the custom rotation control
    const mtr = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetX: 0,
        offsetY: -40,
        cursorStyle: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(imgCursor)}") 12 12, crosshair`,
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: 'rotate',
        render: renderIcon,
        cornerSize: 32,
        withConnection: true,
        sizeX: 20,
        sizeY: 20,
    });

    // Apply the custom rotation control to the object's controls
    this.controls.mtr = mtr;
};

fabric.Object.prototype.initialize = (function (originalInitialize) {
    return function (options) {
        originalInitialize.call(this, options);

        // Ensure the custom properties are always set
        this.set({
            borderColor: '#2cc681',
            cornerColor: '#2cc681',
            cornerSize: 8,
            transparentCorners: true,
        });

        this.setCustomControls();
    };
})(fabric.Object.prototype.initialize);

const FootballField = forwardRef((props, ref) => {
    const { selectedShape, setSelectedShape, selectedColor, showGrid, setHeaderMode, isDragging, isInCanvas, activeInput, selectedShapeLabel, setSelectedShapeLabel, pitchSelection, initialBoardData, setIsCanvasDirty, fontSize, setFontSize } = useContext(BoardContext);

    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [currentShape, setCurrentShape] = useState(null);

    const deleteSelectedObject = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();

            if (activeObject) {
                if (size(activeObject._objects) > 1) {
                    forEach(activeObject._objects, obj => canvas.remove(obj));
                    activeObject.set({
                        hasBorders: false,
                    });
                }
                canvas.remove(activeObject);
                setHeaderMode(1);
            }
        }
    }

    useImperativeHandle(ref, () => ({
        clearAll() {
            if (canvas) {
                canvas.getObjects().forEach((obj) => {
                    canvas.remove(obj);
                });
                setCurrentShape(null);
            }
        },
        deleteSelected() {
            deleteSelectedObject();
        },
        rotateSelected() {
            if (canvas) {
                const obj = canvas.getActiveObject();
                if (obj) {
                    let angle = obj.angle + 15;
                    angle = angle % 360;
                    obj.rotate(angle);
                    canvas.setActiveObject(obj);
                    canvas.renderAll();
                }
            }
        },
        getCanvasObjects() {
            if (canvas) {
                return canvas.toJSON();
            } else {
                return null;
            }
        },
        async getCanvasImage() {
            if (canvas) {
                return canvas.toDataURL({
                    format: 'png', // Format of the image (e.g., 'png', 'jpeg')
                    quality: 1.0,  // Optional: Quality (0-1) for 'jpeg' format
                });
            } else {
                return null;
            }
        }
    }));

    useEffect(() => {
        const fabricCanvas = new fabric.Canvas(canvasRef.current, {
            backgroundColor: 'transparent',
            hoverCursor: 'move',
            defaultCursor: 'default',
            selection: false,
            selectionBorderColor: '#2cc681',
            selectionColor: 'rgb(44 198 129 / 10%)',
        });

        setCanvas(fabricCanvas);

        return () => {
            fabricCanvas.dispose();
        };
    }, []);

    useEffect(() => {
        if (pitchSelection && canvas) {
            fabric.Image.fromURL(`data:image/png;base64,${!showGrid ? pitchSelection.img_url : pitchSelection.img_url_grid}`, function (img) {
                // add background image
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height,
                    customProps: {
                        ...pitchSelection,
                        show_grid: showGrid,
                    },
                });
                setCanvas(canvas);
            });
        }
    }, [pitchSelection, canvas]);

    useEffect(() => {
        if (canvas && initialBoardData?.json) {
            canvas.loadFromJSON(initialBoardData.json, () => {
                canvas.getObjects().forEach((obj) => {
                    const shape = obj?.customProps?.shape;
                    obj.setControlsVisibility(resizeShapeProps[shape] ?? {});
                });
                canvas.renderAll();
                setCanvas(canvas);
            });
        }
    }, [initialBoardData, canvas])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!activeInput) {
                const activeObject = canvas.getActiveObject();
                if ((e.key === 'Backspace' || e.key === 'Delete') && activeObject?.customProps?.type != "text") {
                    deleteSelectedObject();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [canvas, activeInput]);

    useEffect(() => {
        if (canvas) {
            if (selectedShape) {
                canvas.selection = false;
                canvas.defaultCursor = 'crosshair';
            } else {
                canvas.selection = true;
                canvas.defaultCursor = 'default';
            }
        }
    }, [selectedShape, canvas])

    useEffect(() => {
        if (canvas) {
            fabric.Image.fromURL(`data:image/png;base64,${!showGrid ? pitchSelection.img_url : pitchSelection.img_url_grid}`, function (img) {
                // add background image
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height,
                    customProps: {
                        ...pitchSelection,
                        show_grid: showGrid,
                    },
                });
                setCanvas(canvas);
            });
        }
    }, [showGrid])

    useEffect(() => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                if (activeObject?.customProps?.hasLabel) {
                    if (activeObject.type === 'group') {
                        let foundText = false;

                        activeObject.forEachObject((obj) => {
                            if (obj.customProps?.type === 'text') {
                                obj.set({ text: selectedShapeLabel });
                                foundText = true;
                            }
                        });

                        if (foundText) {
                            activeObject.set({ customProps: { ...activeObject.customProps, label: selectedShapeLabel } });
                            canvas.renderAll();
                            return;
                        }
                    }

                    const textObj = new fabric.Text(selectedShapeLabel, {
                        fontSize: activeObject.customProps.fontSize,
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        fill: activeObject.customProps.labelColor,
                        left: activeObject.left,
                        top: activeObject.top,
                        originX: 'center',
                        originY: 'center',
                        selectable: false,
                        customProps: { type: "text" },
                        ...(activeObject.customProps.hasShadow && { shadow: new fabric.Shadow({ color: 'black', blur: 1, offsetY: 0.5, }) }),
                    });

                    const newGroup = new fabric.Group([activeObject, textObj], {
                        ...initialFigureProps,
                        left: activeObject.left,
                        top: activeObject.top,
                        originX: 'center',
                        originY: 'center',
                        selectable: true,
                        customProps: { ...activeObject.customProps, label: selectedShapeLabel }
                    });
                    newGroup.setControlsVisibility(resizeShapeProps.figures)
                    canvas.remove(activeObject);
                    canvas.add(newGroup);
                    canvas.setActiveObject(newGroup);
                    canvas.renderAll();
                }
            }
        }
    }, [selectedShapeLabel, canvas])

    useEffect(() => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                if (activeObject?.customProps?.type == "text") {
                    activeObject.set({ fontSize })
                    if (activeObject.text == "") {
                        const minWidth = 100;
                        activeObject.set({ width: minWidth })
                        activeObject.scaleX = minWidth / activeObject.width;
                    }
                    canvas.renderAll();
                }
            }
        }
    }, [fontSize, canvas])

    useEffect(() => {
        if (isDragging) {
            if (canvas) {
                canvas.discardActiveObject();
                canvas.renderAll();
            }
        }
    }, [canvas, isDragging])

    const handleMouseDown = useCallback((options) => {
        if (options.target) return; // Do not draw if clicking on an existing shape

        const pointer = canvas.getPointer(options.e);
        setStartPos(pointer);
        setIsDrawing(true);

        let shape;
        switch (selectedShape) {
            case 'rectangle':
                shape = new fabric.Rect({
                    left: pointer.x,
                    top: pointer.y,
                    fill: colorCoding[selectedColor + "Shape"],
                    ...initialShapeProps,
                });
                break;
            case 'square':
                shape = new fabric.Rect({
                    left: pointer.x,
                    top: pointer.y,
                    fill: colorCoding[selectedColor + "Shape"],
                    ...initialShapeProps,
                });
                break;
            case 'circle':
                shape = new fabric.Circle({
                    left: pointer.x,
                    top: pointer.y,
                    radius: 1,
                    fill: colorCoding[selectedColor + "Shape"],
                    ...initialShapeProps,
                });
                break;
            case 'oval':
                shape = new fabric.Ellipse({
                    left: pointer.x,
                    top: pointer.y,
                    rx: 1,
                    ry: 1,
                    fill: colorCoding[selectedColor + "Shape"],
                    ...initialShapeProps,
                });
                break;
            case 'arrow':
                shape = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                    ...initialShapeProps,
                    stroke: colorCoding[selectedColor + "Figure"],
                    fill: colorCoding[selectedColor + "Figure"],
                    strokeWidth: 2,
                    originX: 'center',
                    originY: 'center',
                    hasBorders: false,
                    hasControls: false,
                    customProps: { elementIdentifier: "line" },
                    visible: true,
                });
                break;
            case 'curvedLine':
                shape = new fabric.Path(`M ${pointer.x} ${pointer.y} Q ${pointer.x} ${pointer.y} ${pointer.x} ${pointer.y}`, {
                    fill: colorCoding[selectedColor + "Figure"],
                    strokeWidth: 2,
                    objectCaching: false
                });
                break;
            default:
                break;
        }

        if (shape) {
            canvas.add(shape);
            setCurrentShape(shape);
        }
    }, [canvas, selectedShape, selectedColor]);

    const handleMouseMove = useCallback((options) => {
        if (!isDrawing || !currentShape) return;

        const pointer = canvas.getPointer(options.e);
        const { x, y } = pointer;
        const { x: startX, y: startY } = startPos;

        switch (selectedShape) {
            case 'rectangle':
                currentShape.set({
                    width: Math.abs(x - startX),
                    height: Math.abs(y - startY),
                    left: x < startX ? x : startX,
                    top: y < startY ? y : startY,
                    visible: true, // Make it visible when dragging
                });

                break;
            case 'square':
                const side = Math.max(Math.abs(x - startX), Math.abs(y - startY));
                currentShape.set({
                    width: side,
                    height: side,
                    left: x < startX ? startX - side : startX,
                    top: y < startY ? startY - side : startY,
                    visible: true, // Make it visible when dragging
                });
                break;
            case 'circle':
                const radius = Math.hypot(x - startX, y - startY) / 2;
                currentShape.set({
                    radius,
                    left: startX,
                    top: startY,
                    visible: true, // Make it visible when dragging
                });
                break;
            case 'oval':
                currentShape.set({
                    rx: Math.abs(x - startX) / 2,
                    ry: Math.abs(y - startY) / 2,
                    left: startX < x ? startX : x,
                    top: startY < y ? startY : y,
                    visible: true, // Make it visible when dragging
                });
                break;
            default:
                break;
        }

        canvas.renderAll();
    }, [isDrawing, currentShape, canvas, selectedShape, startPos]);

    const handleMouseUp = useCallback((options) => {
        if (currentShape) {
            const MIN_SIZE = 5;
            const { width, height, rx, ry, x1, y1, x2, y2 } = currentShape;

            const validShape =
                (selectedShape === 'circle' && currentShape.radius >= MIN_SIZE) ||
                (selectedShape === 'oval' && (rx >= MIN_SIZE && ry >= MIN_SIZE)) ||
                (width >= MIN_SIZE && height >= MIN_SIZE);

            if (!validShape) {
                canvas.remove(currentShape);
            } else {
                currentShape.setCoords(); // Update the coordinates
                currentShape.set({ customProps: { shape: selectedShape } })
                currentShape.setControlsVisibility(resizeShapeProps[selectedShape] ?? {});
                canvas.setActiveObject(currentShape);
                canvas.renderAll();
            }
        }

        const pointer = canvas.getPointer(options.e);
        const isSamePosition = startPos.x === pointer.x && startPos.y === pointer.y;
        if (isSamePosition) {
            setSelectedShape(null);
        }

        setIsDrawing(false);
        setCurrentShape(null);
    }, [canvas, currentShape]);

    const handleSelectOn = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
            if (activeObject?.customProps?.type == "text") {
                const fontSize = activeObject.fontSize ?? 14;
                setFontSize(fontSize)
                setHeaderMode(5);
            } else if (activeObject?.customProps?.hasLabel) {
                setHeaderMode(4);
                setSelectedShapeLabel(activeObject.customProps.label);
            } else if (activeObject.customProps?.rotate) {
                setHeaderMode(2);
            } else {
                if (size(activeObject._objects) > 1) {
                    activeObject.set(initialGroupProps);
                    activeObject.setControlsVisibility(resizeShapeProps["group"] ?? {})
                    if (activeObject.customProps?.rotate) {
                        activeObject.setControlsVisibility({ mtr: true })
                    }
                    canvas.renderAll(); // Re-render the canvas to apply changes
                    setHeaderMode(3);
                } else {
                    setHeaderMode(2);
                }
                if ([2, 3].includes(activeObject.customProps?.group)) {
                    setHeaderMode(2);
                }
            }
        }
    }

    const handleSelectOff = (options) => {
        setHeaderMode(1);
        setSelectedShapeLabel("");

        if (options?.deselected?.[0]?.customProps?.type == "text") {
            setFontSize(14);
            const activeObject = options.deselected[0];
            if (activeObject.text === '') {
                canvas.remove(activeObject);
                canvas.renderAll();
            }
        }

        if (canvas) {
            canvas.defaultCursor = 'default';
        }
    }

    const handleFigureDrop = (event) => {
        const figureSvg = {
            "ball": ball,
            "x-cone": x_cone,
            "cone": cone,
            "goal": goal,
            "x-goal": x_goal,
            "mannequin": mannequin,
            "ladder": ladder,
            "hurdle": hurdle,
            "slalom-pole": slalom_pole,
            "player": player,
            "goalkeeper": goalkeeper,
            "triangle-player": triangle_player,
            "circle-player": circle_player,
        }

        const rect = canvas.lowerCanvasEl.getBoundingClientRect();
        const x = event.detail.x - rect.left;
        const y = event.detail.y - rect.top;
        const figure = event.detail.figure;
        const color = event.detail.color;
        const coloringType = event.detail.coloringType;
        const hasLabel = event.detail.hasLabel;
        const labelColor = event.detail.labelColor;
        const defaultLabel = event.detail.defaultLabel;
        const fontSize = event.detail.fontSize;
        const hasShadow = event.detail.hasShadow;
        const group = event.detail.group;

        fabric.loadSVGFromURL(figureSvg[figure], (objects, options) => {
            const svg = fabric.util.groupSVGElements(objects, options);
            svg.set({
                ...initialFigureProps,
                left: x,
                top: y,
                scaleX: 1.25, scaleY: 1.25,
                originX: 'center',
                originY: 'center',
                ...(color && { [coloringType]: colorCoding[color + "Figure"] }),
                customProps: {
                    hasLabel, labelColor, defaultLabel, fontSize, hasShadow, group,
                    label: "",
                    rotate: true,
                    shape: "figures"
                }
            });
            if (svg?._objects) {
                forEach(svg._objects, obj => {
                    if (obj.fill === "inherite") {
                        obj.set({ fill: colorCoding[color + "Figure"] })
                    }
                })
            }
            svg.setControlsVisibility(resizeShapeProps.figures)
            canvas.setActiveObject(svg);
            canvas.add(svg);

            if (hasLabel && defaultLabel) {
                const textObj = new fabric.Text(defaultLabel, {
                    fontSize: fontSize,
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    fill: labelColor,
                    left: svg.left,
                    top: svg.top,
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    customProps: { type: "text" },
                    ...(hasShadow && { shadow: new fabric.Shadow({ color: 'black', blur: 1, offsetY: 0.5, }) }),
                });
                const newGroup = new fabric.Group([svg, textObj], {
                    ...initialFigureProps,
                    left: svg.left,
                    top: svg.top,
                    originX: 'center',
                    originY: 'center',
                    selectable: true,
                    customProps: { ...svg.customProps, label: defaultLabel }
                });
                newGroup.setControlsVisibility(resizeShapeProps.figures)
                canvas.remove(svg);
                canvas.add(newGroup);
                canvas.setActiveObject(newGroup);
            }

            canvas.renderAll();
        });
    };

    const markCanvasDirty = () => setIsCanvasDirty(true);

    const handleTextInputAdd = (event) => {
        setHeaderMode(5);
        const rect = canvas.lowerCanvasEl.getBoundingClientRect();
        const x = event.detail.x - rect.left;
        const y = event.detail.y - rect.top;

        const minWidth = 100;

        const input = new fabric.IText('', {
            ...initialTextInputProps,
            left: x,
            top: y,
            fontSize,
            customProps: { type: "text", shape: "text_input" }
        });
        input.set({ width: minWidth });
        input.scaleX = minWidth / input.width;
        input.setControlsVisibility(resizeShapeProps.text_input)
        input.enterEditing();

        canvas.setActiveObject(input);
        canvas.add(input);
        canvas.renderAll();
    };

    useEffect(() => {
        if (canvas) {
            canvas.on('mouse:down', handleMouseDown);
            canvas.on('mouse:move', handleMouseMove);
            canvas.on('mouse:up', handleMouseUp);
            canvas.on('selection:cleared', handleSelectOff);
            canvas.on('selection:created', handleSelectOn);
            canvas.on('selection:updated', handleSelectOn);
            window.addEventListener('figure:drop', handleFigureDrop);
            window.addEventListener('inputtext:add', handleTextInputAdd);

            // Listen to object added and modified events
            canvas.on('object:added', markCanvasDirty);
            canvas.on('object:modified', markCanvasDirty);
        }

        return () => {
            if (canvas) {
                canvas.off('mouse:down', handleMouseDown);
                canvas.off('mouse:move', handleMouseMove);
                canvas.off('mouse:up', handleMouseUp);
                canvas.off('selection:cleared', handleSelectOff);
                canvas.off('selection:created', handleSelectOn);
                canvas.off('selection:updated', handleSelectOn);
                window.removeEventListener('figure:drop', handleFigureDrop);
                window.removeEventListener('inputtext:add', handleTextInputAdd);

                canvas.off('object:added', markCanvasDirty);
                canvas.off('object:modified', markCanvasDirty);
            }
        };
    }, [canvas, handleMouseDown, handleMouseMove, handleMouseUp]);

    return (
        <div className={styles['diagram-creator-canvas-outer']}>
            <div className={styles['diagram-creator-canvas-container']}>
                <div className={styles["diagram-creator-canvas"]} style={{ border: `2px dashed ${isInCanvas ? "#45d695" : (isDragging ? "#45d6954d" : "transparent")}` }}>
                    <div className={styles['canvas-area']}>
                        <canvas width={1000} height={563} id={"canvasId"} className={styles["canvas-board"]} ref={canvasRef} />
                    </div>
                </div>
            </div>
            <img style={{ display: "none" }} />
        </div>
    );
});

export default FootballField;
