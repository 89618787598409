export const draggableTools = [
    {
        figure: "ball",
        group: 2
    },
    {
        figure: "x-cone",
        group: 2,
        customColor: true,
        coloringType: "fill"
    },
    {
        figure: "cone",
        group: 2,
        customColor: true,
        coloringType: "fill"
    },
    {
        figure: "goal",
        group: 2,
    },
    {
        figure: "x-goal",
        group: 2,
    },
    {
        figure: "mannequin",
        group: 2,
    },
    {
        figure: "ladder",
        group: 2,
    },
    {
        figure: "hurdle",
        group: 2,
    },
    {
        figure: "slalom-pole",
        group: 2,
    },
    {
        figure: "player",
        group: 3,
        customColor: true,
        coloringType: "fill",
        hasLabel: true,
        labelColor: "black",
        fontSize: 11,
    },
    {
        figure: "goalkeeper",
        group: 3,
        customColor: true,
        coloringType: "fill",
        hasLabel: true,
        labelColor: "black",
        defaultLabel: "GK",
        fontSize: 11,
    },
    {
        figure: "triangle-player",
        group: 3,
        customColor: true,
        coloringType: "fill",
        hasLabel: true,
        labelColor: "white",
        fontSize: 9,
        hasShadow: true,
    },
    {
        figure: "circle-player",
        group: 3,
        customColor: true,
        coloringType: "fill",
        hasLabel: true,
        labelColor: "white",
        fontSize: 10,
        hasShadow: true,
    },
    {
        figure: "text",
        group: 5
    },
];

export const toolColors = ["green", "blue", "yellow", "red", "black", "grey"];