export const initialShapeProps = {
    width: 1,
    height: 1,
    visible: false,
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 8,
    transparentCorners: true,
    rotationCursor: "pointer",
};

export const initialFigureProps = {
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 8,
    transparentCorners: true
};

export const initialGroupProps = {
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 8,
    transparentCorners: true,
    hasRotatingPoint: false,
};

export const dotInitialProps = {
    originX: "center",
    originY: "center",
    left: 0,
    top: 0,
    width: 20,
    height: 20,
    angle: 0,
    hasControls: false,
    hasBorder: false,
    elementIdentifier: "control",
    fill: "transparent",
    stroke: "#2cc681",
    visible: false,
    strokeWidth: 2,
};

export const initialTextInputProps = {
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 12,
    transparentCorners: true,
    fontFamily: 'arial',
    fontSize: 14,
    padding: 5,
    editingBorderColor: '#2cc681',
    textAlign: 'left',
    editable: true,
    strokeWidth: 4,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    lockUniScaling: true,
};

export const resizeShapeProps = {
    "square": {
        tl: true, //top-left
        mt: false, // middle-top
        tr: true, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: true, // bottom-left
        mb: false, //middle-bottom
        br: true, //bottom-right 
    },
    "rectangle": {
        tl: true, //top-left
        mt: true, // middle-top
        tr: true, //top-right
        ml: true, //middle-left
        mr: true, //middle-right
        bl: true, // bottom-left
        mb: true, //middle-bottom
        br: true, //bottom-right
    },
    "circle": {
        tl: true, //top-left
        mt: false, // middle-top
        tr: true, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: true, // bottom-left
        mb: false, //middle-bottom
        br: true, //bottom-right 
    },
    "oval": {
        tl: true, //top-left
        mt: true, // middle-top
        tr: true, //top-right
        ml: true, //middle-left
        mr: true, //middle-right
        bl: true, // bottom-left
        mb: true, //middle-bottom
        br: true, //bottom-right
    },
    "figures": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: true, //rotation-point
    },
    "group": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: false, //rotation-point
    },
    "text_input": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: true, //rotation-point
    },
};